import React from 'react'
import Layout from '../layouts/Layout'
import BasicHero from '../components/Hero/BasicHero'
import { Container } from 'theme-ui'
import { Link } from 'gatsby'

function TermsOfUse() {
  return (
    <Layout theme={'dark'}>
      <BasicHero heading="Privacy Policy" />
      <section>
        <Container
          sx={{
            pb: '0px!important',
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <div
            sx={{
              width: ['100%', null, null, '30%'],
            }}
          >
            <h2
              sx={{
                pb: 4,
                fontSize: 38,
                display: ['none', null, null, 'block'],
              }}
            >
              Privacy Policy
            </h2>
            <b
              sx={{
                mt: 4,
                color: 'brand.matisse',
              }}
            >
              Effective Date:
            </b>{' '}
            February 5, 2025
            <br />
            <b
              sx={{
                color: 'brand.matisse',
              }}
            >
              Last Updated:
            </b>{' '}
            February 5, 2025
          </div>
          <div
            sx={{
              width: ['100%', null, null, '70%'],
              a: {
                color: 'brand.matisse',
                fontWeight: 500,
                textDecoration: 'underline',
              },
              h2: {
                fontSize: 38,
                pb: 3,
              },
              h3: {
                fontSize: 20,
                py: 4,
              },
              ul: {
                li: {
                  fontWeight: 300,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <p>
              Thank you for your interest in Curo Group Holdings LLC d/b/a
              Attain Finance (“Attain Finance"). Attain Finance is the parent
              company to a group of companies which provide, or arrange, for
              consumers to obtain various financial products and services such
              as loans and the servicing and collection of lending activity. We
              welcome you to our website at{' '}
              <a href="https://www.attainfinance.com">
                https://www.attainfinance.com
              </a>
              , as well as all related webpages (the “Site”), and products and
              services provided by us and certain third parties (collectively,
              together with the Site, our “Service”). By accessing or using the
              Service or the Site, you acknowledge that you have read,
              understood, and agree to the terms of this privacy policy
              (“Privacy Policy”) and the Terms of Use. If you cannot agree to
              the Privacy Policy, then please do not use the Service or the
              Site.
            </p>

            <p>
              As you use our Service, you may provide us with information about
              you, including information that can be used to identify you. This
              Privacy Policy explains (1) your responsibilities when using the
              Site and the Service, and (2) how Attain Finance may collect, use,
              and disclose information you provide us and other information we
              receive related to your use of the Service.
            </p>

            <p>
              If you have questions about the Privacy Policy, please contact us:
            </p>
            <p>
              Phone: <a href="tel:1-877-252-4835">1-877-252-4835</a>
            </p>
            <p>
              Email:{' '}
              <a href="mailto:privacy@attainfinance.com">
                privacy@attainfinance.com
              </a>
            </p>
          </div>
        </Container>
        <Container
          sx={{
            pt: '60px!important',
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <div
            sx={{
              width: ['100%', null, null, '30%'],
            }}
          >
            <h2
              sx={{
                pb: 4,
                fontSize: 24,
                lineHeight: 1.5,
                maxWidth: ['100%', null, null, 300],
                textAlign: 'left',
              }}
            >
              What information does Attain Finance collect?
            </h2>
          </div>
          <div
            sx={{
              width: ['100%', null, null, '70%'],
              a: {
                color: 'brand.matisse',
                fontWeight: 500,
                textDecoration: 'underline',
              },
              h2: {
                fontSize: 38,
                pb: 3,
              },
              h3: {
                fontSize: 20,
                py: 4,
              },
              ul: {
                li: {
                  fontWeight: 300,
                  lineHeight: 1.5,
                },
              },
            }}
          >
            <h3 sx={{ pt: '0px!important' }}>
              What does Attain Finance do with your personal information?
            </h3>
            <p>
              We collect your name, location, phone and email address if you
              provide it to us. We use this email address to provide you notices
              of filings and investor related information that you requested.
              This email address is not used for any other purpose. Each email
              provides you an opportunity to unsubscribe and discontinue
              receiving any emails.
            </p>
            <p>
              We use Google Analytics, a web analytics service provided by
              Google, Inc. Google Analytics uses Cookies or other tracking
              technologies to help us analyze how users interact with the Site,
              compile reports on the Site’s activity, and provide other services
              related to Site activity and usage. The technologies used by
              Google may collect information such as your IP address, time of
              visit, whether you are a returning visitor, and any referring
              website. The technologies used by Google Analytics do not gather
              information that personally identifies you. The information
              generated by Google Analytics will be transmitted to and stored by
              Google and will be subject to Google’s privacy policies. To learn
              more about Google’s partner services and to learn how to opt-out
              of tracking of analytics by Google, click&nbsp;
              <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
            </p>
            <h3>How We Respond to Do Not Track Signals</h3>
            <p>
              Our Site does not currently have the capability to recognize the
              various Do Not Track signals or mechanisms from different web
              browsers. For more information on Do Not Track please visit&nbsp;
              <a href="https://allaboutdnt.com/">https://allaboutdnt.com</a>.
            </p>
            <h3>How does Attain Finance use my personal information?</h3>
            <p>Attain Finance uses your personal information to:</p>
            <ul>
              <li>
                <p>Provide you with our Service;</p>
              </li>
              <li>
                <p>
                  Maintain general communications with you about the Site and
                  Service;
                </p>
              </li>
            </ul>
            <p>
              We may deidentify and aggregate data collected through the Service
              and use it for any purpose.
            </p>
            <h3>How does Attain Finance protect my information?</h3>
            <p>
              We take reasonable administrative, technical, and physical
              security precautions to protect the personal information collected
              or maintained by us against loss, unauthorized access, and illegal
              use or disclosure. As examples, these measures include computer
              safeguards and secured files and buildings, among other things.
              Unfortunately, the Internet cannot be guaranteed to be 100%
              secure, and we cannot ensure or warrant the security of any
              information you provide to us.
            </p>
            <h3>Children’s Privacy</h3>
            <p>
              The Service is not intended for use by children. We do not
              knowingly collect personal information from children under the age
              of 16 years. Please contact us if you believe we may have
              unknowingly collected information about a child under 16 years of
              age, and we will take reasonable prompt steps to delete any such
              personal information.
            </p>
            <h3>Contact Us</h3>
            <p>You can contact us at: </p>
            <p>
              Phone:&nbsp;<a href="tel:1-877-252-4835">1-877-252-4835</a>
            </p>
            <p>
              Email:&nbsp;
              <a href="mailto:privacy@attainfinance.com">
                privacy@attainfinance.com
              </a>
            </p>
            <h3>Changes</h3>
            <p>
              Our Privacy Policy reflects our current business practices and is
              subject to change without notice.
            </p>
            <p>
              We reserve the right, in our sole discretion, to modify, add,
              revise or delete provisions of our Privacy Policy at any time. If
              our practices change regarding your Personal Information, we will
              make reasonable efforts to provide notice to you of these changes.
              Your use of the Service after an update to the Privacy Policy
              means that you accept the new Privacy Policy. We will post the
              effective date of any change at the beginning of our Privacy
              Policy. We encourage you to visit our Site regularly so that you
              remain aware of how our Privacy Policy may affect you.
              <br />
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default TermsOfUse
